.Textarea {
  width: 100%;
  padding-top: 1rem;
  height: 7.5rem;
  outline: none;
  border-radius: .25rem;
  border: 1px solid #ccc;
  background: #fff;
  padding-left: 1rem;
  &::placeholder {
    font-size: 1.2rem;
  }
}