.Wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
}

.Container {
  width: 40rem;
  padding: 2rem;
  background: #fff;
  border-radius: .5rem;
  position: relative;
  z-index: 1001;
}


.Form {
  width: 100%;
}
