.Button {
  cursor: pointer;
  outline: none;
  border: none;
  height: 3rem;
  padding: 0 2rem;
  background: #21ba45;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: .25rem;
  background: #21ba45;
}
