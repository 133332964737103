.Wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #227831;
}

.Form {
  width: 40rem;
  padding: 2rem;
  background: #fff;
  border-radius: .5rem;
}