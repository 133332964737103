@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap);
.Login_Wrapper__3REWv{width:100%;height:100vh;display:flex;justify-content:center;align-items:center;background:#227831}.Login_Form__28CAu{width:40rem;padding:2rem;background:#fff;border-radius:.5rem}
.Group_Group__2PxPR{margin-bottom:1.5rem}.Group_Group__2PxPR:last-child{margin-bottom:0}.Group_GroupFull__Rr_UD{width:100% !important}.Group_Label__2RNe0{color:#333;display:block;margin-bottom:.5rem;font-size:1.3rem;font-weight:600}.Group_Error__ct_UP{display:block;color:red;font-size:1.2rem;margin-top:.25rem}
.Input_Input__SNRl4{width:100%;height:3.8rem;outline:none;border-radius:.25rem;border:1px solid #ccc;background:#fff;padding-left:1rem}.Input_Input__SNRl4::-webkit-input-placeholder{font-size:1.2rem}.Input_Input__SNRl4:-ms-input-placeholder{font-size:1.2rem}.Input_Input__SNRl4::placeholder{font-size:1.2rem}
.Button_Button__2Ajf-{cursor:pointer;outline:none;border:none;height:4rem;padding:0 3rem;background:#21ba45;color:#fff;font-size:1.4rem;font-weight:600;border-radius:.25rem}.Button_Green__3-UVv{background:#21ba45}.Button_Orange__hGRpJ{background:orange}
.Paginator_Pagination__Ln_39{position:absolute;right:1.5rem;display:flex;align-items:center}.Paginator_Info__2JtX_{font-size:1.3rem;margin-right:1.5rem}.Paginator_Buttons__3-rQC{display:flex;margin-left:1rem}.Paginator_Button__1pPWf{height:3.2rem;min-width:3.2rem;border-radius:4px;border:1px solid #cfd7df;color:#12344d;cursor:pointer;margin-right:.5rem;font-size:14px;vertical-align:middle;-webkit-font-smoothing:antialiased;transition:.1s ease-in;background-image:linear-gradient(to bottom, #fff, #f3f5f7);display:flex;align-items:center;justify-content:center}.Paginator_Button__1pPWf img{width:1.2rem}.Paginator_Prev__3Voeh img{-webkit-transform:rotate(180deg);transform:rotate(180deg)}
.Page_Wrapper__xj-Hg{width:100%;padding-left:60px}.Page_Title__2fGs5{background:#227831;padding:2rem 2rem;color:#fff;font-size:2rem;font-weight:600}.Page_Content__1LSxg{padding:2rem 2rem}.Page_Controls__3GVky{display:flex;align-items:center;justify-content:space-between;background-color:#ececec;height:56px;padding:0 15px;padding-right:16rem}.Page_ControlsEnd__3nEyq{justify-content:flex-end}
.Navbar_Navbar__2q9tz{position:fixed;left:0;top:0;display:block;width:60px;height:100%;background:#1b1c1d;z-index:100}.Navbar_Navbar__2q9tz a{color:#fff;display:flex;align-items:center;justify-content:center;font-weight:600;opacity:.9;width:70%;margin:0 auto;position:relative;border-radius:.5rem}.Navbar_Navbar__2q9tz a:after{content:"";display:block;padding-bottom:100%}.Navbar_Navbar__2q9tz a:before{background:rgba(255,255,255,.08)}.Navbar_Navbar__2q9tz a:hover{background:#444;color:#fff}.Navbar_Navbar__2q9tz a:hover span{opacity:1;pointer-events:none;transition:all .25s ease-in}.Navbar_Navbar__2q9tz a i{font-size:1.6rem;margin-right:0;position:relative}.Navbar_Navbar__2q9tz a span{display:block;opacity:0;padding:.5rem 1rem;right:-1.5rem;position:absolute;background:#1b1c1d;white-space:nowrap;font-size:1.3rem;border-radius:.5rem;pointer-events:none;-webkit-transform:translateX(100%);transform:translateX(100%);transition:all .25s ease-in}.Navbar_Navbar__2q9tz a span:after{content:"";position:absolute;left:-0.35rem;top:1rem;width:0;height:0;border-top:.5rem solid rgba(0,0,0,0);border-right:.5rem solid #1b1c1d;border-bottom:.5rem solid rgba(0,0,0,0)}.Navbar_Navbar__2q9tz button{position:absolute;bottom:2rem;left:1.5rem;font-size:1.3rem !important}.Navbar_Logo__2iG0s{display:flex;align-items:center;height:5.9rem;background:#222;margin-bottom:1.5rem}.Navbar_Logo__2iG0s img{display:block;margin:0 auto;width:67%}
.Grid_Grid__1He54{margin-top:2rem;display:flex;justify-content:space-between;flex-wrap:wrap}.Grid_Grid__1He54>div{width:24%}
.Device_Device__1-6ZC{background:#ebebeb;padding:2rem;width:100% !important;display:flex;justify-content:space-between;flex-wrap:wrap;margin-bottom:2rem}.Device_Device__1-6ZC>div{width:24%}
.Textarea_Textarea__uPE5d{width:100%;padding-top:1rem;height:7.5rem;outline:none;border-radius:.25rem;border:1px solid #ccc;background:#fff;padding-left:1rem}.Textarea_Textarea__uPE5d::-webkit-input-placeholder{font-size:1.2rem}.Textarea_Textarea__uPE5d:-ms-input-placeholder{font-size:1.2rem}.Textarea_Textarea__uPE5d::placeholder{font-size:1.2rem}
.Create_Button__3KOCt{cursor:pointer;outline:none;border:none;height:4rem;padding:0 3rem;background:#21ba45;color:#fff;font-size:1.4rem;font-weight:600;border-radius:.25rem;margin-right:2rem}.Create_Green__39qYi{background:#21ba45}.Create_Orange__e-3OS{background:orange}
.Table_Table__tBXHq{width:100%;text-align:left;position:relative;border-collapse:collapse}.Table_Th__dSbfm{top:0;z-index:10;position:-webkit-sticky;position:sticky;background:#f5f7f9;box-shadow:0px 1px 0px rgba(207,215,223,.25);border-radius:2.5px 2.5px 0px 0px;padding:2rem 1rem;font-size:1.3rem}.Table_Td__3Vn6E{padding:1.5rem 1rem;font-size:1.3rem;line-height:135%}.Table_TdImage__2Pu6_{width:8.5rem}.Table_TdImage__2Pu6_ img{display:block;border-radius:.5rem}.Table_TdControls__1V-fb{width:17rem}.Table_TdControls__1V-fb div{display:flex;align-items:center}.Table_Tr__1lMd5{cursor:pointer;background:#fff;border-bottom:1px solid #ebeff3}.Table_Tr__1lMd5:hover{background:#ebeff3}.Table_TrCard__1814d{background:#ecf5ed}.Table_Checkbox__27bnm{width:5rem}.Table_Image__3WaAq{width:6rem}
.Search_Search__2wNw2{position:relative;height:32px;width:48rem}.Search_Search__2wNw2 input{border:1px solid #cfd7df;border-radius:4px;padding:4px 8px;padding-left:3rem;color:#92a2b1;position:absolute;width:100%;height:100%}.Search_Search__2wNw2 img{position:absolute;left:1rem;top:0;bottom:0;z-index:1;margin:auto}
.Modal_Wrapper__2HZ3a{width:100%;height:100vh;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;right:0;bottom:0;z-index:1000;background:rgba(0,0,0,.8)}.Modal_Container__37bDZ{width:40rem;padding:2rem;background:#fff;border-radius:.5rem;position:relative;z-index:1001}.Modal_Form__T6_VS{width:100%}
.Export_Button__k0NQO{cursor:pointer;outline:none;border:none;height:3rem;padding:0 2rem;background:#21ba45;color:#fff;font-size:1.2rem;font-weight:600;border-radius:.25rem;background:#21ba45}
.Current_Info__2rp5G{padding:2rem;display:flex;justify-content:space-between;align-items:center;border-radius:.5rem;background-color:#ececec}.Current_Item__3dIAP span{display:block;font-size:1.3rem}.Current_Item__3dIAP p{font-weight:800;font-size:1.4rem}
html {
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
}

iframe {
    pointer-events: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
}

h3 {
    font-size: 1.5rem;
}

tr, td {
    font-size: 1.3rem!important;
}

.select__placeholder {
    font-size: 1.3rem!important;
}

.loading {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 22rem);
    height: calc(100% - 12rem);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.company__info div {
    font-size: 1.5rem;
}

.edit {
    top: -.1rem;
    left: .2rem;
}

.g__logout {
    width: 4.2rem;
    height: 4.2rem;
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    left: 0!important;
    right: 0!important;
    margin: auto !important;
}

.g__logout i {
    margin-right: 0;
    position: relative;
    left: -.25rem;
}
.g__logout span {
    display: block;
    opacity: 0;
    padding: .5rem 1rem;
    right: -1.5rem;
    position: absolute;
    background: #1b1c1d;
    white-space: nowrap;
    font-size: 1.3rem;
    border-radius: .5rem;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: all .25s ease-in;
}

.g__logout span:after {
    content: "";
    position: absolute;
    left: -.35rem;
    top: 1rem;
    width: 0;
    height: 0;
    border-top: .5rem solid transparent;
    border-right: .5rem solid #1b1c1d;
    border-bottom: .5rem solid transparent;
}

