.Wrapper {
  width: 100%;
  padding-left: 60px;
}
.Title {
  background: #227831;
  padding: 2rem 2rem;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
}

.Content {
  padding: 2rem 2rem;
}

.Controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ececec;
  height: 56px;
  padding: 0 15px;
  padding-right: 16rem;
}

.ControlsEnd {
  justify-content: flex-end;
}