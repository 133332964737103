.Navbar {
  position: fixed;
  left: 0;
  top: 0;
  display: block;
  width: 60px;
  height: 100%;
  background: #1b1c1d;
  z-index: 100;
  a {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    opacity: .9;
    width: 70%;
    margin: 0 auto;
    position: relative;
    border-radius: .5rem;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    &:before {
      background: hsla(0,0%,100%,.08);
    }
    &:hover {
      background: #444;
      color: #fff;
      span {
        opacity: 1;
        pointer-events: none;
        transition: all .25s ease-in;
      }
    }
    i {
      font-size: 1.6rem;
      margin-right: 0;
      position: relative;
    }
    span {
      display: block;
      opacity: 0;
      padding: .5rem 1rem;
      right: -1.5rem;
      position: absolute;
      background: #1b1c1d;
      white-space: nowrap;
      font-size: 1.3rem;
      border-radius: .5rem;
      pointer-events: none;
      transform: translateX(100%);
      transition: all .25s ease-in;
      &:after {
        content: "";
        position: absolute;
        left: -.35rem;
        top: 1rem;
        width: 0;
        height: 0;
        border-top: .5rem solid transparent;
        border-right: .5rem solid #1b1c1d;
        border-bottom: .5rem solid transparent;
      }
    }
  }
  button {
    position: absolute;
    bottom: 2rem;
    left: 1.5rem;
    font-size: 1.3rem!important;
  }
}

.Logo {
  display: flex;
  align-items: center;
  height: 5.9rem;
  background: #222;
  margin-bottom: 1.5rem;
  img {
    display: block;
    margin:  0 auto;
    width: 67%;
  }
}
