.Device {
  background: #ebebeb;
  padding: 2rem;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  > div {
    width: 24%;
  }
}